/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/

@mixin g-section_holder_header {
	margin: 0 auto;
	max-width: $global-site-width-max;
	padding-left: 32px;
	padding-right: 32px;

	@include media(md) {
		padding-left: 22px;
		padding-right: 22px;
	}

	@include media(sm) {
		padding-left: 10px;
		padding-right: 10px;
	}
}
