/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/

@mixin g-section_holder {
	margin: 0 auto;
	max-width: $global-content-width-max;
	padding-left: grid-margin(lg);
	padding-right: grid-margin(lg);

	@include media(md) {
		padding-left: grid-margin(md);
		padding-right: grid-margin(md);
	}

	@include media(sm) {
		padding-left: grid-margin(sm);
		padding-right: grid-margin(sm);
	}
}
