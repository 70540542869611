/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/

@mixin g-radio(
	$_: root,
	$_state: default
) {
	$border: $global-line solid adjust-color-to-bg($color-bg, rgba($color-white, 0.25), rgba($color-black, 0.25));
	$stale: 0 0 0 10px adjust-color-to-bg($color-bg, rgba($color-black, 1), rgba($color-white, 1)) inset;

	@if $_ == root {
		align-items: center;
		color: $color-grey-50;
		display: flex;
		font-size: 12px;
		font-weight: 500;
		position: relative;
		user-select: none;
	}

	@if $_ == _input {
		height: 24px;
		left: 0;
		opacity: 0;
		position: absolute;
		width: 24px;

		@include rtl {
			left: initial;
			right: 0;
		}
	}

	@if $_ == _icon {
		@if $_state == default {
			$control-size: 24px;

			appearance: none;
			background: transparent;
			border: $border;
			border-radius: $control-size;
			cursor: pointer;
			display: inline-block;
			height: $control-size;
			margin-inline-end: rh(3);
			min-height: $control-size;
			min-width: $control-size;
			position: relative;
			transition: $motion-ease;
			transition-property: border, box-shadow;
			vertical-align: sub;
			width: $control-size;

			&::-ms-check {
				display: none;
			}

			&::before {
				$dot-size: 14px;

				background-color: adjust-color-to-bg($color-bg);
				border-radius: $dot-size;
				content: '';
				height: $dot-size;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%) scale(0);
				transition: $motion-ease;
				transition-property: transform;
				width: $dot-size;
			}
		}

		@if $_state == m-active {
			transform: scale(0.9);
		}

		@if $_state == m-hover {
			border-color: $color-action;
		}

		@if $_state == m-checked {
			border-color: adjust-color-to-bg($color-bg);
			border-width: 2px;

			&::before {
				transform: translate(-50%, -50%) scale(1);
			}
		}

		@if $_state == m-disabled {
			background-color: $color-grey95;
			border-color: $color-grey73;
			cursor: default;
			transform: scale(1);

			&::before {
				background-color: $color-grey46;
			}
		}

		@if $_state == m-invalid {
			border-color: $color-error;
		}
	}
}
