
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/

// TODO: remove old unused colors
$color-black: hsl(0, 0%, 0%);
$color-grey18: hsl(0, 0%, 18%);
$color-grey25: hsl(0, 0%, 25%);
$color-grey46: hsl(0, 0%, 46%);
$color-grey73: hsl(0, 0%, 73%);
$color-grey88: hsl(0, 0%, 88%);
$color-grey95: hsl(0, 0%, 95%);
$color-white: hsl(0, 0%, 100%);
$color-blue: hsl(223, 77%, 55%);
$color-blue38: hsl(223, 77%, 38%);
$color-blue98: hsl(223, 80%, 98%);
$color-red: hsl(0, 100%, 42%);
$color-red95: hsl(0, 100%, 95%);
$color-yellow: hsl(37, 91%, 55%);
$color-yellow54: hsl(28, 100%, 54%);
$color-yellow94: hsl(36, 93%, 94%);
$color-green: hsl(123, 46%, 34%);
$color-green19: hsl(122, 52%, 19%);
$color-green94: hsl(123, 23%, 95%);
$color-pink: hsl(335, 100%, 37%);
$color-pink31: hsl(335, 100%, 31%);
$color-pink93: hsl(335, 100%, 93%);

// Gianvito Rossi
$color-beige: #cdb5a7;
$color-black: #000001;
$color-white: #ffffff;
$color-soft-beige: #dbcbc1;
$color-soft-beige-2: #ebddd3;
$color-soft-beige-muted: #f0e9e5;
$color-light-beige: #f8f5f5;
$color-almost-black: #262626;
$color-grey-50: #757575; // Needs for a11y reasons. In styleguide #808080
$color-grey-25: #c5c5c5;
$color-grey-15: #d8d8d8;
$color-grey-5: #f2f2f2;
$color-red: #d54a4a;
$color-bright-red: #dd3636;
$color-soft-red: #feeeee;
$color-soft-beige-muted-2: #f8f5f5;

// Buttons
$color-button: $color-black;
$color-button-text: $color-white;
$color-button-hover: $color-almost-black;
$color-button-disabled: $color-grey-50;
$color-button-disabled-text: $color-grey-5;
$color-button-outline-disabled: $color-grey-50;
$color-button-dark-bg: $color-white;
$color-button-dark-bg-text: $color-black;
$color-button-dark-bg-hover: $color-grey-5;
$color-button-dark-bg-disabled: $color-grey-25;
$color-button-dark-bg-disabled-text: $color-grey-50;
$color-button-secondary: $color-beige;
$color-button-secondary-text: $color-black;
$color-button-secondary-hover: $color-soft-beige;
$color-button-secondary-disabled: $color-soft-beige-muted;
$color-button-link-disabled: $color-grey-25;

// Applied colors
$color-primary: $color-black;
$color-accent: $color-red;
$color-accent-obj: $color-pink93;
// text
$color-text: $color-black;
$color-text-dimmed: $color-grey-50;
$color-text-grey: $color-grey25;
$color-divider: $color-grey-5;
$color-divider-light: $color-grey-15;
// actions
$color-action: $color-black;
$color-action-obj: $color-black;
$color-action-disabled: $color-grey73;
// statuses
$color-error: $color-red;
$color-error-obj: $color-soft-red;
$color-success: $color-green;
$color-success-obj: $color-grey-5;
$color-warning: $color-yellow;
$color-warning-obj: $color-grey-5;
$color-info: $color-black;
$color-info-obj: $color-grey-5;
// regions
$color-bg: $color-white;
$color-bg-shade: $color-grey95;
$color-bg-shade2: $color-soft-beige-muted-2;
$color-bg-utility-bar: $color-soft-beige-muted;
$color-bg-header-line-1: $color-white;
$color-bg-header-line-2: $color-bg;
$color-bg-header-promo: $color-info-obj;
$color-bg-search-promo: $color-success-obj;
$color-fg-header-promo: $color-info;
$color-fg-search-promo: $color-green;
$color-bg-footer-region-1: $color-light-beige;
$color-bg-footer-region-2: $color-light-beige;
$color-bg-panel: $color-white;
$color-bg-panel-head: $color-grey-5;
$color-bg-overlay: $color-black;
$color-bg-notification-panel: $color-grey-5;
// components
$color-bg-promo: $color-soft-beige-muted;
$color-fg-promo: $color-grey-50;

// If you need test themes please copy colors from colors.md doc
//$theme: 'yellow';
//@if ($theme == 'yellow') {
//	$color-primary: hsl(213, 24%, 28%);
//	$color-accent: hsl(34, 99%, 70%);
// ...
