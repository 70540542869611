.b-load_more {
	margin-top: 20px;
	text-align: center;

	&.m-previous {
		margin-top: 15px;
	}

	&-button {
		font-size: 14px;
		padding: 0 40px;

		@include media(sm) {
			width: 100%;
		}
	}
}
