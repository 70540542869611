.b-slide_panel { // TODO: maybe need better naming
	display: flex;
	height: 100%;
	transition: transform $motion-ease-popups;

	&.m-active_level_1 {
		transform: translateX(0);
	}

	&.m-active_level_2 {
		transform: translateX(-100%);

		@include rtl {
			transform: translateX(100%);
		}
	}

	&-container {
		min-width: 100%;
		overflow-y: auto;

		&.m-level_1 {
			.b-slide_panel-content {
				display: none;
			}
		}
	}

	&-header {
		align-items: center;
		background-color: $color-grey-5;
		display: flex;
		justify-content: space-between;
		min-height: 48px;
		padding: 0;

		@include media(sm) {
			justify-content: flex-end;
		}
	}

	&-header_action {
		text-align: center;
		white-space: nowrap;
		width: 100%;

		&.m-primary {
			padding-inline-start: 48px;
		}
	}

	&-clear {
		color: $color-action;
		cursor: pointer;
		font-weight: 500;
		padding: 0 12px;
		position: relative;

		@include media(lg-down) {
			margin: 0 20px;
			padding: 0;
		}

		&::before {
			background: $color-black;
			content: '';
			height: 16px;
			left: -20px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;

			@include rtl {
				left: initial;
				right: 0;
			}
		}

		&::after {
			background-color: $color-black;
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	&-title {
		font-size: 14px;
		font-weight: 500;
		padding: 0 12px;

		@include media(lg-down) {
			letter-spacing: 1px;
			padding: 0 18px;
			text-transform: uppercase;
		}
	}

	&-back {
		@include g-button_iconed;
	}

	&-close_button {
		@include g-button_iconed;

		svg {
			height: 16px;
			width: 16px;
		}
	}

	&-items {
		padding: 0 15px;

		[data-refinement-id='price'] {
			display: none;
		}
	}

	&-item {
		align-items: center;
		box-shadow: 0 1px 0 0 $color-divider-light;
		cursor: pointer;
		display: flex;
		font-weight: 500;
		justify-content: space-between;
		padding: 15px 0;

		@include hover-supported {
			&:hover {
				color: $color-action;
				text-decoration: none;
			}
		}
	}

	&-item_icon {
		flex-shrink: 0;
	}

	&-name {
		font-size: 18px;

		@include media(sm) {
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 5px;
			text-transform: uppercase;
		}
	}

	&-action {
		margin: 30px 15px 0;

		&.m-submenu {
			margin: 16px 0 0;
		}

		.b-button {
			font-size: 14px;
		}
	}

	&-applied_filters {
		@include media(sm) {
			color: $color-grey-50;
			line-height: 1.14;
		}
	}

	&-submenu {
		padding: 15px;
	}

	.b-refinement-list.m-color,
	.b-refinement-list.m-size {
		margin: 16px 0 32px;
	}
}
