/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 50px, 'md': 32px, 'sm': 15px),
	)
);

@import 'grids_tools';
