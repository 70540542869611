.b-refinements_slide_panel {
	@include media(lg-down) {
		@include g-dialog_backdrop;

		&.m-active {
			overflow-y: hidden;
		}
	}

	&-inner {
		@include media(lg-down) {
			background-color: $color-bg-panel;
			bottom: 0;
			height: 100%;
			max-width: 400px;
			overflow: hidden;
			position: fixed;
			right: 0;
			top: 0;
			transform: translateX(100%);
			transition: $motion-ease-popups;
			transition-property: visibility, transform;
			visibility: hidden;
			width: 90vw;
			z-index: z(modal);

			@include rtl {
				left: 0;
				right: initial;
				transform: translateX(-100%);
			}

			&.m-opened {
				box-shadow: $depth-3;
				transform: translateX(0);
				visibility: visible;

				@include rtl {
					transform: translateX(0);
				}
			}
		}

		@include media(md-down) {
			&.m-no_transition {
				transition: none !important; // stylelint-disable-line
			}
		}
	}
}
