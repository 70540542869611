.l-search {
	@include g-section_holder;

	margin-bottom: 40px;

	&-content {
		position: relative;

		&::after {
			background-color: rgba($color-white, 0.6);
			bottom: 0;
			content: '';
			cursor: wait;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: opacity $motion-ease;
			visibility: hidden;
		}

		&.m-busy::after {
			opacity: 1;
			visibility: visible;
		}
	}
}
