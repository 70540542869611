/*md

# b-promo_caption

Promo caption is the content of promo components.

## Structure

`b-promo_caption` consist from 3 main elements:

* title
* subtitle
* actions container

All of this elements are optional.

## Variation

For sake of simpleness and robustness Boilerplate do not provide predefined sizes and styles
variations.

## Examples

### All elements

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Different order

```html_example
<div class="b-promo_caption">
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Only title and CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Join
		</a>
	</div>
</div>
```

### 2 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
	</div>
</div>
```

### 3 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
		<a
			class="b-button m-link"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Kids
		</a>
	</div>
</div>
```

### Without CTA

```html_example
<a
	class="b-promo_caption"
	href="$url('Search-Show', 'cgid', 'category-2')$"
>
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
</a>
```

## Example in component

### b-promo_info_box

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join today
			</a>
		</div>
	</div>
</div>
```

### b-promo-box

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

*/

.b-promo_caption {
	$root: &;

	align-self: center;

	&-title {
		@include g-heading_2;

		margin: 0 0 10px;

		&.spacing-1 {
			letter-spacing: 1px;
		}
		&.spacing-2 {
			letter-spacing: 2px;
		}
		&.spacing-3 {
			letter-spacing: 3px;
		}
		&.spacing-4 {
			letter-spacing: 4px;
		}
		&.spacing-5 {
			letter-spacing: 5px;
		}
		&.spacing-6 {
			letter-spacing: 6px;
		}
		&.spacing-7 {
			letter-spacing: 7px;
		}
		&.spacing-8 {
			letter-spacing: 8px;
		}
		&.spacing-9 {
			letter-spacing: 9px;
		}
		&.spacing-10 {
			letter-spacing: 10px;
		}
	}

	&-subtitle {
		font-weight: 500;
		margin: 10px 0 20px;

		&.desktop-margin-left-1 {
			@include media(md-up) {
				margin-left: 10px;
			}
		}
		&.desktop-margin-left-2 {
			@include media(md-up) {
				margin-left: 20px;
			}
		}
		&.desktop-margin-left-3 {
			@include media(md-up) {
				margin-left: 30px;
			}
		}
		&.desktop-margin-left-4 {
			@include media(md-up) {
				margin-left: 40px;
			}
		}
		&.desktop-margin-left-5 {
			@include media(md-up) {
				margin-left: 50px;
			}
		}
		&.desktop-margin-left-6 {
			@include media(md-up) {
				margin-left: 60px;
			}
		}
		&.desktop-margin-left-7 {
			@include media(md-up) {
				margin-left: 70px;
			}
		}
		&.desktop-margin-left-8 {
			@include media(md-up) {
				margin-left: 80px;
			}
		}
		&.desktop-margin-left-9 {
			@include media(md-up) {
				margin-left: 90px;
			}
		}
		&.desktop-margin-left-10 {
			@include media(md-up) {
				margin-left: 100px;
			}
		}
		&.desktop-margin-left-20 {
			@include media(md-up) {
				margin-left: 200px;
			}
		}
		&.desktop-margin-left-30 {
			@include media(md-up) {
				margin-left: 300px;
			}
		}
		&.desktop-margin-left-40 {
			@include media(md-up) {
				margin-left: 400px;
			}
		}
		&.desktop-margin-left-50 {
			@include media(md-up) {
				margin-left: 500px;
			}
		}
		&.desktop-margin-left-60 {
			@include media(md-up) {
				margin-left: 600px;
			}
		}

		&.desktop-margin-right-1 {
			@include media(md-up) {
				margin-right: 10px;
			}
		}
		&.desktop-margin-right-2 {
			@include media(md-up) {
				margin-right: 20px;
			}
		}
		&.desktop-margin-right-3 {
			@include media(md-up) {
				margin-right: 30px;
			}
		}
		&.desktop-margin-right-4 {
			@include media(md-up) {
				margin-right: 40px;
			}
		}
		&.desktop-margin-right-5 {
			@include media(md-up) {
				margin-right: 50px;
			}
		}
		&.desktop-margin-right-6 {
			@include media(md-up) {
				margin-right: 60px;
			}
		}
		&.desktop-margin-right-7 {
			@include media(md-up) {
				margin-right: 70px;
			}
		}
		&.desktop-margin-right-8 {
			@include media(md-up) {
				margin-right: 80px;
			}
		}
		&.desktop-margin-right-9 {
			@include media(md-up) {
				margin-right: 90px;
			}
		}
		&.desktop-margin-right-10 {
			@include media(md-up) {
				margin-right: 100px;
			}
		}
		&.desktop-margin-right-20 {
			@include media(md-up) {
				margin-right: 200px;
			}
		}
		&.desktop-margin-right-30 {
			@include media(md-up) {
				margin-right: 300px;
			}
		}
		&.desktop-margin-right-40 {
			@include media(md-up) {
				margin-right: 400px;
			}
		}
		&.desktop-margin-right-50 {
			@include media(md-up) {
				margin-right: 500px;
			}
		}
		&.desktop-margin-right-60 {
			@include media(md-up) {
				margin-right: 600px;
			}
		}

		&.mobile-margin-left-1 {
			@include media(md-down) {
				margin-left: 10px;
			}
		}
		&.mobile-margin-left-2 {
			@include media(md-down) {
				margin-left: 20px;
			}
		}
		&.mobile-margin-left-3 {
			@include media(md-down) {
				margin-left: 30px;
			}
		}
		&.mobile-margin-left-4 {
			@include media(md-down) {
				margin-left: 40px;
			}
		}
		&.mobile-margin-left-5 {
			@include media(md-down) {
				margin-left: 50px;
			}
		}
		&.mobile-margin-left-6 {
			@include media(md-down) {
				margin-left: 60px;
			}
		}
		&.mobile-margin-left-7 {
			@include media(md-down) {
				margin-left: 70px;
			}
		}
		&.mobile-margin-left-8 {
			@include media(md-down) {
				margin-left: 80px;
			}
		}
		&.mobile-margin-left-9 {
			@include media(md-down) {
				margin-left: 90px;
			}
		}
		&.mobile-margin-left-10 {
			@include media(md-down) {
				margin-left: 100px;
			}
		}
		&.mobile-margin-left-20 {
			@include media(md-down) {
				margin-left: 200px;
			}
		}
		&.mobile-margin-left-30 {
			@include media(md-down) {
				margin-left: 300px;
			}
		}
		&.mobile-margin-left-40 {
			@include media(md-down) {
				margin-left: 400px;
			}
		}

		&.mobile-margin-right-1 {
			@include media(md-down) {
				margin-right: 10px;
			}
		}
		&.mobile-margin-right-2 {
			@include media(md-down) {
				margin-right: 20px;
			}
		}
		&.mobile-margin-right-3 {
			@include media(md-down) {
				margin-right: 30px;
			}
		}
		&.mobile-margin-right-4 {
			@include media(md-down) {
				margin-right: 40px;
			}
		}
		&.mobile-margin-right-5 {
			@include media(md-down) {
				margin-right: 50px;
			}
		}
		&.mobile-margin-right-6 {
			@include media(md-down) {
				margin-right: 60px;
			}
		}
		&.mobile-margin-right-7 {
			@include media(md-down) {
				margin-right: 70px;
			}
		}
		&.mobile-margin-right-8 {
			@include media(md-down) {
				margin-right: 80px;
			}
		}
		&.mobile-margin-right-9 {
			@include media(md-down) {
				margin-right: 90px;
			}
		}
		&.mobile-margin-right-10 {
			@include media(md-down) {
				margin-right: 100px;
			}
		}
		&.mobile-margin-right-20 {
			@include media(md-down) {
				margin-right: 200px;
			}
		}
		&.mobile-margin-right-30 {
			@include media(md-down) {
				margin-right: 300px;
			}
		}
		&.mobile-margin-right-40 {
			@include media(md-down) {
				margin-right: 400px;
			}
		}
	}

	&-actions {
		align-items: baseline;
		display: inline-flex;
		flex-wrap: wrap;
		margin-top: 20px;

		@include media(md-down) {
			display: block;
		}

		@include media(md-up) {
			margin: 0 -15px;
			width: auto;
		}

		a {
			font-size: 14px;
			padding: 10px 39px;

			@include media(md-up) {
				margin-left: 15px;
			}

			@include media(sm) {
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}
}

.b-hero_carousel {
	.b-promo_caption {
		&-title {
			@include g-heading_1;
		}

		&-subtitle {
			@include media(md-down) {
				display: none; // TODO: check this behaviour. Maybe sm-down?
			}
		}
	}
}
