.b-size_guide_link {
	@include g-link;

	align-items: center;
	display: flex;
	font-weight: 400;
	margin-top: 16px;
	position: relative;
	text-decoration: underline;
	text-transform: capitalize;

	&:hover {
		text-decoration: none;
	}
}
