.b-plp_actions {
	@include g-grid;

	align-items: center;
	padding: 0 0 7px;

	@include media(sm) {
		box-shadow: none;
	}

	@include media(xl) {
		display: flex;
	}

	&-sorting {
		align-items: center;
		display: flex;
		height: $size-input-height;
		margin-inline-start: auto;
		position: relative;

		@include media(lg-down) {
			grid-column: span 3 / -1;
			width: 100%;
		}

		.b-select {
			@include media(lg-down) {
				opacity: 0;
			}
		}

		.b-select-input {
			padding: 0 40px 0 19px;
			padding-inline-start: 19px;
		}
	}

	&-sorting_label {
		@include media(xl) {
			color: $color-grey-50;
			margin-inline-end: 16px;
			white-space: nowrap;
		}

		@include media(lg-down) {
			align-items: center;
			border: $global-line solid adjust-color-to-bg($color-bg, rgba($color-white, 0.3), rgba($color-black, 0.3));
			display: flex;
			font-weight: 400;
			height: 100%;
			justify-content: center;
			left: 0;
			letter-spacing: 0.5px;
			position: absolute;
			text-transform: uppercase;
			top: 0;
			width: 100%;
		}

		&::after {
			@include media(xl) {
				content: ':';
			}
		}

		svg {
			display: none;

			@include media(lg-down) {
				display: block;
				margin-inline-start: rh(2);
			}
		}
	}

	&-refinements_toggle {
		@include g-button;

		background: $color-action-obj;
		border: 2px solid $color-action-obj;
		border-radius: $global-radius;
		color: adjust-color-to-bg($color-action-obj);
		font-weight: 700;
		letter-spacing: 0.036em;
		padding: rh(0 6);

		@include media(lg-down) {
			grid-column: span 3;
		}

		@include media(xl) {
			display: none;
		}

		@include media(sm) {
			font-weight: 400;
		}
	}

	&-results_count {
		font-size: 16px;
		font-weight: 400;

		@include media(lg-down) {
			grid-column: span 6;
		}

		@include media(sm) {
			margin-bottom: 11px;
			text-align: center;
		}
	}
}
