.b-search_tips {
	margin-top: rh(9);

	&-title {
		font-size: 16px;
		margin-bottom: rh(4);
	}

	&-list {
		font-weight: 500;
		list-style: disc inside;
	}
}
