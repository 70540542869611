/*md

# Refinement swatches

## Color swatch

```html_example
<div
	class="b-refinement_swatch m-swatch"
	role="checkbox"
	aria-checked="false"
	aria-disabled="false"
	aria-label=" Refine by Color: Green"
	tabindex="0"
	data-widget="button"
	data-widget-event-click="updateView"
	data-event-click.prevent="handleClick"
	data-event-keydown="handleKeydown"
	data-checked="false"
	data-href="#"
>
	<span class="b-refinement_swatch-value" data-tau="refinements_option_value">
		<span class="b-refinement_swatch-color_value">
			<svg style="background-color:#2e7d32" width="48" height="48" focusable="false"></svg>
		</span>
		<span class="b-refinement_swatch-value_overlay"></span>
	</span>
	<span class="b-refinement_swatch-name" data-tau="refinements_option_name">
		Green
		<span class="b-refinement_swatch-hit_count"> (2) </span>
	</span>
</div>
```

## Color swatch selected

```html_example
<div
	class="b-refinement_swatch m-swatch m-checked"
	aria-label=" Refine by Color: Green"
	data-widget="button"
	data-widget-event-click="updateView"
	data-event-click.prevent="handleClick"
	data-event-keydown="handleKeydown"
	data-checked="true"
	role="checkbox"
	tabindex="0"
	aria-checked="true"
	aria-disabled="false"
	data-href="#"
	data-initialized="1"
>
	<span class="b-refinement_swatch-value" data-tau="refinements_option_value">
		<span class="b-refinement_swatch-color_value">
			<svg style="background-color:#2e7d32" width="48" height="48" focusable="false"></svg>
		</span>
		<span class="b-refinement_swatch-value_overlay"></span>
	</span>
	<span class="b-refinement_swatch-name" data-tau="refinements_option_name">
		Green <span class="b-refinement_swatch-hit_count"> (2) </span>
	</span>
</div>
```

## Color swatch disabled

```html_example
<div
	class="b-refinement_swatch m-swatch m-disabled"
	aria-label=" Refine by Color: Green"
	data-widget="button"
	data-widget-event-click="updateView"
	data-event-click.prevent="handleClick"
	data-event-keydown="handleKeydown"
	data-checked="false"
	aria-disabled="true"
	role="checkbox"
	tabindex="0"
	aria-checked="false"
	data-href="#"
>
	<span class="b-refinement_swatch-value" data-tau="refinements_option_value">
		<span class="b-refinement_swatch-color_value">
			<svg style="background-color:#2e7d32" width="48" height="48" focusable="false"></svg>
		</span>
		<span class="b-refinement_swatch-value_overlay"></span>
	</span>
	<span class="b-refinement_swatch-name" data-tau="refinements_option_name">
		Green <span class="b-refinement_swatch-hit_count"> (2) </span>
	</span>
</div>
```

## Size swatch

```html_example
<div
	class="b-refinement_swatch m-S"
	aria-label="Refine by Size: S"
	data-widget="button"
	data-widget-event-click="updateView"
	data-event-click.prevent="handleClick"
	data-event-keydown="handleKeydown"
	data-checked="false"
	role="checkbox"
	tabindex="0"
	aria-checked="false"
	aria-disabled="false"
	data-href="#"
	title="Refine by Size: S"
>
	<span class="b-refinement_swatch-value" data-tau="refinements_option_value">
		S
		<span class="b-refinement_swatch-value_overlay"></span>
	</span>
	<span class="b-refinement_swatch-name" data-tau="refinements_option_name">
		<span class="b-refinement_swatch-hit_count"> (24) </span>
	</span>
</div>
```

## Size swatch selected

```html_example
<div
	class="b-refinement_swatch m-S m-checked"
	aria-label="Refine by Size: S"
	data-widget="button"
	data-widget-event-click="updateView"
	data-event-click.prevent="handleClick"
	data-event-keydown="handleKeydown"
	data-checked="true"
	role="checkbox"
	tabindex="0"
	aria-checked="true"
	aria-disabled="false"
	data-href="#"
	title="Refine by Size: S"
>
	<span class="b-refinement_swatch-value" data-tau="refinements_option_value">
		S
		<span class="b-refinement_swatch-value_overlay"></span>
	</span>
	<span class="b-refinement_swatch-name" data-tau="refinements_option_name">
		<span class="b-refinement_swatch-hit_count"> (24) </span>
	</span>
</div>
```

## Size swatch disabled

```html_example
<div
	class="b-refinement_swatch m-S m-disabled"
	aria-label="Refine by Size: S"
	data-widget="button"
	data-widget-event-click="updateView"
	data-event-click.prevent="handleClick"
	data-event-keydown="handleKeydown"
	data-checked="false"
	role="checkbox"
	tabindex="0"
	aria-checked="false"
	aria-disabled="true"
	data-href="#"
	title="Refine by Size: S"
>
	<span class="b-refinement_swatch-value" data-tau="refinements_option_value">
		S
		<span class="b-refinement_swatch-value_overlay"></span>
	</span>
	<span class="b-refinement_swatch-name" data-tau="refinements_option_name">
		<span class="b-refinement_swatch-hit_count"> (24) </span>
	</span>
</div>
```

## Size swatch flex width

```html_example
<div
	class="b-refinement_swatch m-S m-flex"
	aria-label="Refine by Size: S"
	data-widget="button"
	data-widget-event-click="updateView"
	data-event-click.prevent="handleClick"
	data-event-keydown="handleKeydown"
	data-checked="false"
	role="checkbox"
	tabindex="0"
	aria-checked="false"
	aria-disabled="false"
	data-href="#"
	title="Refine by Size: S"
>
	<span class="b-refinement_swatch-value" data-tau="refinements_option_value">
		2-3 yrs
		<span class="b-refinement_swatch-value_overlay"></span>
	</span>
	<span class="b-refinement_swatch-name" data-tau="refinements_option_name">
		(24)
	</span>
</div>
```
*/

.b-refinement_swatch {
	align-items: center;
	cursor: pointer;
	display: flex;
	font-weight: 500;
	padding: 4px 2px 0;
	text-align: center;
	user-select: none;

	&.m-flex { // Special narrowed modification
		font-size: 14px;
		white-space: nowrap;
	}

	&.m-swatch {
		width: auto;
		word-break: break-all;
	}

	&[aria-disabled='true'] {
		color: $color-text-dimmed;
		pointer-events: none;
	}

	&-name {
		display: block;
		margin-top: rh(1);
	}

	&-hit_count {
		display: block;
	}

	&-value {
		align-items: center;
		border: 1px solid $color-black;
		display: flex;
		font-size: 18px;
		font-weight: 600;
		height: 24px;
		justify-content: center;
		margin-right: 15px;
		overflow: hidden;
		position: relative;
		width: 24px;

		.b-refinement_swatch:hover & {
			box-shadow: 0 0 0 1px $color-action;
		}

		.b-refinement_swatch[aria-checked='true'] & {
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE0IDEwIj4KICAgIDxwYXRoIGZpbGw9IiMwMDAwMDEiIGQ9Ik0xMS44NDcuMDkzYy4xMDctLjExNiAxLjQzIDEuMjQgMS4zNzMgMS4zMmwtLjA3Ni4wOTQtNy43MDcgOC40MDdMLjc4NCA0LjhjLS4wNDctLjA1MS4yNTQtLjQ2Ny41NjgtLjg0Ny4yOTUtLjM1OC42MDEtLjY4NS42NDItLjY1bC41MzUuNjEgMi45MDggMy4xNzIgNi40MS02Ljk5MnoiLz4KPC9zdmc+Cg==');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 12.4px;
		}

		&::after {
			.b-refinement_swatch[aria-disabled='true'] & {
				background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1'  preserveAspectRatio='none' viewBox='0 0 48 48'><path d='M48 0 L0 48 ' vector-effect='non-scaling-stroke' stroke='white' stroke-width='7'/><path d='M48 0 L0 48' vector-effect='non-scaling-stroke' stroke='darkgray' stroke-width='2'/></svg>");
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 100% 100%, auto;
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		.b-refinement_swatch.m-swatch & {
			background-color: $color-bg-shade2;
			border-radius: 100%;
			height: 40px;
			max-width: 40px;
			min-width: 40px;
			padding: 0;
		}

		.b-refinement_swatch.m-flex &,
		.b-refinement_swatch.m-onesize & {
			font-size: 14px;
			font-weight: 500;
			line-height: 1.2;
		}
	}

	&-value_overlay {
		border: 0 solid $color-white;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: $motion-ease;
		transition-property: border-width;

		.b-refinement_swatch:hover & {
			border-width: 3px;
		}

		.b-refinement_swatch[aria-checked='true'] & {
			border-color: $color-white;
			border-width: 3px;
		}

		.b-refinement_swatch.m-swatch & {
			border-radius: 100%;
		}
	}

	&-color_value {
		align-items: center;
		display: flex;
		height: 100%;
		width: 100%;

		.b-refinement_swatch[aria-disabled='true'] & {
			opacity: 0.5;
		}
	}
}
