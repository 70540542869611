/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/

@mixin g-link_hamburger(
	$_mod: default,
	$_divider: null
) {
	@if $_mod == default {
		@include g-link;

		align-items: center;
		cursor: pointer;
		display: flex;
		min-height: 48px;
		padding: 0 20px;
		position: relative;
		text-decoration: none;
		text-transform: uppercase;
		width: 100%;

		@if $_divider {
			&::after {
				background: $color-divider;
				content: '';
				height: 1px;
				left: 15px;
				position: absolute;
				right: 15px;
				top: 100%;
			}
		}
	}

	@if $_mod == highlight {
		color: $color-accent;
	}
}
