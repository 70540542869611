.b-refinements_accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);

		&.m-price {
			display: none;
		}
	}

	&-button {
		@include g-accordion(_control);

		font-size: 15px;
		width: 100%;

		&:hover {
			@include g-accordion(_control, hover);
		}
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}

		// Make content accessible until JS is loaded
		.b-refinements_accordion-item:not([data-initialized='1']) & {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);

		padding-bottom: 25px;
		padding-left: 0;
	}
}
