.l-plp_grid {
	align-items: stretch;
	box-shadow: inset 0 1px 0 0 $color-divider-light;
	display: grid;
	grid-gap: rh(8 2);
	grid-template-areas:
		'. . slot1'
		'. . slot2';
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: stretch;
	padding-top: 20px;
	position: relative;

	@include media(md) {
		grid-gap: rh(8 4);
	}

	@include media(lg-up) {
		grid-gap: rh(12 5);
	}

	@include media(sm) {
		box-shadow: none;
		grid-gap: 10px 9px;
		grid-template-areas:
			'. .'
			'slot1 slot1'
			'. .'
			'slot2 slot2';
		grid-template-columns: 1fr 1fr;
	}

	&::after {
		background-color: rgba($color-white, 0.6);
		bottom: 0;
		content: '';
		cursor: wait;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity $motion-ease;
		visibility: hidden;
	}

	&.m-busy {
		&::after {
			opacity: 1;
			visibility: visible;
		}
	}

	&-actions {
		grid-column: 1/-1;
	}

	&-slot_1 {
		align-self: start;
		grid-area: slot1;
		height: initial;
	}

	&-slot_2 {
		align-self: start;
		grid-area: slot2;
		height: initial;
	}
}
