/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link();
}
```

*/
@mixin g-link($_mod: 'default') {
	color: inherit;
	cursor: pointer;
	text-underline-offset: 4px;

	@if ($_mod == 'default') {
		text-decoration: none;

		@include hover-supported {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	@if ($_mod == 'underlined') {
		text-decoration: underline;

		@include hover-supported {
			&:hover {
				text-decoration: none;
			}
		}
	}
}
