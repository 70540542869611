/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/

@mixin g-checkbox(
	$_: root,
	$_state: default
) {
	@if $_ == root {
		color: $color-grey-50;
		display: flex;
		font-size: 12px;
		font-weight: 500;
		position: relative;
		user-select: none;
	}

	@if $_ == _input {
		cursor: pointer;
		height: 24px;
		left: 0;
		opacity: 0;
		position: absolute;
		width: 24px;
		z-index: z(components, checkbox, input);

		@include rtl {
			left: initial;
			right: 0;
		}
	}

	@if $_ == _icon {
		@if $_state == default {
			background-color: $color-bg;
			border: $global-line solid $color-black;
			border-radius: $global-radius;
			cursor: pointer;
			margin-inline-end: 14px;
			min-width: 24px;
			position: relative;
			transition: $motion-ease;
			transition-property: border-color;

			path {
				stroke: $color-black;
				stroke-linecap: round;
				stroke-linejoin: round;
				stroke-width: 2.5px;
				transform: scale(0);
				transform-origin: center center;
				transition: $motion-ease;
				transition-property: transform;
			}
		}

		@if $_state == m-active {
			transform: scale(0.9);
		}

		@if $_state == m-hover {
			border-color: $color-action;
		}

		@if $_state == m-checked {
			border: 2px solid adjust-color-to-bg($color-bg);

			path {
				transform: scale(1);
			}
		}

		@if $_state == m-disabled {
			background-color: $color-grey95;
			border-color: $color-grey73;
			cursor: default;
			transform: scale(1);

			path {
				stroke: $color-grey46;
			}
		}

		@if $_state == m-invalid {
			border-color: $color-error;
		}
	}
}
