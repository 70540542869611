.b-refinements_panel {
	color: adjust-color-to-bg($color-bg);
	padding-right: 15px;

	&-inner {}

	&-title {
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 1px;
		padding: 7px 0 19px;
		text-transform: uppercase;

		@include media(md-down) {
			display: none;
		}
	}

	&-content {}
}
