/*md
@no-stat

# RTL selector

This mixin is designed to alter styles for RTL languages.

It mostly needed for alter position:absolute left|right coords, but could be used
as facade for different selectors.

## Usage

```scss
.component {
	left: 0;
	@include rtl {
		left: initial;
		right: 0;
	};
}
```
*/

@mixin rtl {
	html[dir='rtl'] & { // stylelint-disable-line selector-no-qualifying-type
		@content;
	}
}
