.l-search_list {
	margin-top: rh(18);

	&-content {
		@include media(lg-up) {
			width: grid-span(7, 'lg');
		}
	}

	&-actions {}

	&-item {
		margin-bottom: rh(7);
	}
}
