.b-customer_service {
	margin-top: rh(8);

	&-title {
		font-size: 16px;
		margin-bottom: rh(5);
	}

	&-list {
		font-weight: 500;
		list-style: none;
		padding: 0;
	}

	&-item {
		align-items: center;
		display: flex;
		margin-bottom: rh(3);

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-link {
		margin-inline-start: rh(3);
	}
}
