.b-search_promo {
	@include g-section_holder;

	margin-bottom: 33px;

	.b-promo_box-picture {
		@include g-image_container(_container, aspect-ratio(1264px, 359px));

		@include media(sm) {
			min-height: 359px;
		}

		img {
			@include g-image_container(_img);

			max-width: 50%;

			@include media(sm) {
				max-width: 100%;
				max-height: 50%;
			}
		}
	}

	.b-promo_box.m-caption_right .b-promo_box-caption {
		@include media(sm) {
			text-align: center;
			height: 50%;
			padding: 0 15px;
			transform: translate(0, 50%);
			display: flex;
			flex-direction: column;
			justify-content: center;

			.b-promo_caption-title {
				margin: 0;
			}
		}
	}
}
