.b-plp_no_results {
	font-size: 14px;
	font-weight: 400;
	text-align: center;

	&-count {
		border-bottom: 2px solid $color-divider;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: rh(8);
		padding: 20px 0;
	}

	&-actions {
		margin-top: 40px;
	}
}
