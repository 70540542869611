.b-header_search {
	@include g-section_holder;

	display: flex;
	justify-content: center;
	padding-bottom: rh(9);
	padding-top: rh(16);
	text-align: center;

	@include media(sm) {
		padding-top: rh(10);
	}

	&-wrap {
		width: 100%;

		@include media(md-up) {
			padding: 0;
			width: grid-span($column: 6);
		}
	}

	&-title {
		@include g-heading_3;

		font-weight: 700;
	}

	&-title_no_results {
		@include g-heading_4;

		text-align: center;
		word-break: break-word;
	}

	&-no_products {
		margin-top: rh(3);
	}

	&-keywords_wrap {
		display: inline-flex;
	}

	&-keywords {
		font-weight: 500;
		text-decoration: underline;
		transition: color $motion-fast;

		&:hover {
			color: $color-action;
		}
	}

	&-suggestion {
		font-size: 16px;
		margin-top: rh(4);
		text-align: start;
	}
}
