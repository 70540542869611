/*md

# b-scrollable_table

This table has an inner scroll on mobile devices with sticky cell headers. On desktop and tablet devices will be shown the usual table.

## Example
```html_example
<div class="b-scrollable_table">
	<div class="b-scrollable_table-content">
	    <table class="b-scrollable_table-table">
	        <tbody>
	            <tr>
	                <th scope="row">
	                    <strong>US</strong>
	                </th>
	                <td>
	                    <strong>2</strong>
	                </td>
	                <td>
	                    <strong>4</strong>
	                </td>
	                <td>
	                    <strong>6</strong>
	                </td>
	                <td>
	                    <strong>8</strong>
	                </td>
	                <td>
	                    <strong>10</strong>
	                </td>
	                <td>
	                    <strong>12</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">
	                    <strong>EURO</strong>
	                </th>
	                <td>
	                    <strong>34</strong>
	                </td>
	                <td>
	                    <strong>36</strong>
	                </td>
	                <td>
	                    <strong>38</strong>
	                </td>
	                <td>
	                    <strong>40</strong>
	                </td>
	                <td>
	                    <strong>42</strong>
	                </td>
	                <td>
	                    <strong>44</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">
	                    <strong>UK</strong>
	                </th>
	                <td>
	                    <strong>6</strong>
	                </td>
	                <td>
	                    <strong>8</strong>
	                </td>
	                <td>
	                    <strong>10</strong>
	                </td>
	                <td>
	                    <strong>12</strong>
	                </td>
	                <td>
	                    <strong>14</strong>
	                </td>
	                <td>
	                    <strong>16</strong>
	                </td>
	            </tr>
	            <tr>
	                <th scope="row">CHEST</th>
	                <td>80/31</td>
	                <td>80/32</td>
	                <td>86/34</td>
	                <td>91/36</td>
	                <td>96/38</td>
	                <td>101/40</td>
	            </tr>
	            <tr>
	                <th scope="row">WAIST</th>
	                <td>63/25</td>
	                <td>65/26</td>
	                <td>65/27</td>
	                <td>74/30</td>
	                <td>79/31</td>
	                <td>84/33</td>
	            </tr>
	            <tr>
	                <th scope="row">HIPS</th>
	                <td>89/35</td>
	                <td>91/36</td>
	                <td>94/37</td>
	                <td>99/39</td>
	                <td>104/41</td>
	                <td>109/43</td>
	            </tr>
	        </tbody>
	    </table>
	</div>
</div>
```
*/

.b-scrollable_table {
	font-size: 12px;
	margin: 16px 0;
	position: relative;

	th {
		background-clip: padding-box;
		background-color: $color-bg;
		font-weight: normal;
		left: 0;
		min-width: 120px;
		padding: 10px 6px;
		position: sticky;
		text-align: center;
		width: 1px;
	}

	td {
		min-width: 40px;
		padding: 10px 6px;
		text-align: center;
	}

	&-content {
		overflow: auto;
		position: relative;
	}

	&-table {
		width: 100%;
	}

	&-row {
		&.m-highlighted {
			background-color: $color-soft-beige-2;

			th {
				background-color: $color-soft-beige-2;
			}
		}
	}
}
