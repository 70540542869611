/*md

# Refinement link

TBD

*/

.b-refinement_link {
	color: adjust-color-to-bg($color-bg);
	cursor: pointer;
	padding-left: 15px;
	text-decoration: none;

	&:hover {
		color: rgba($color-action, 0.8);
		text-decoration: none;
	}

	&.m-selected {
		font-weight: 700;
		letter-spacing: 1px;
		padding-left: 0;
		pointer-events: none;
		text-transform: uppercase;
	}

	&.m-return {
		align-items: center;
		display: flex;
		font-weight: 400;
		letter-spacing: 1px;
		padding-left: 0;
		text-transform: uppercase;

		svg {
			margin-inline-end: rh(1);
			transform: rotate(90deg);
		}
	}
}
