/* stylelint-disable selector-no-vendor-prefix */
@mixin placeholder($font-size) {
	&::-webkit-input-placeholder {
		font-size: $font-size;
		@content;
	}

	&::-moz-placeholder {
		font-size: $font-size;
		@content;
	}

	&:-ms-input-placeholder {
		font-size: $font-size;
		@content;
	}

	&:-moz-placeholder {
		font-size: $font-size;
		@content;
	}
}
