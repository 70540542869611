/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
$media: (
	sm: 'screen and (max-width: #{$break-md - 1})',
	md: 'screen and (min-width: #{$break-md}) and (max-width: #{$break-lg - 1})',
	lg: 'screen and (min-width: #{$break-lg}) and (max-width: #{$break-xl - 1})',
	xl: 'screen and (min-width: #{$break-xl})',
	md-up: 'screen and (min-width: #{$break-md})',
	md-down: 'screen and (max-width: #{$break-lg - 1})',
	lg-up: 'screen and (min-width: #{$break-lg})',
	lg-down: 'screen and (max-width: #{$break-xl - 1})'
) !default;

@use 'sass:map';
@use 'sass:list';
@use 'sass:string';

@mixin media($query) {
	$__query: null;

	@each $type in $query {
		@if map.has-key($media, $type) {
			$__query: list.append($__query, string.unquote(map.get($media, $type)), comma);
		} @else {
			@warn $type;
		}
	}

	@media #{$__query} {
		@content;
	}
}
