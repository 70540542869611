/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/

@use 'sass:string';

@mixin hide($type, $important: false) {
	$__important: if($important == true, '!important', '');

	@if $type == text {
		background-color: transparent string.unquote($__important);
		color: transparent string.unquote($__important);
		font-size: 0 string.unquote($__important);
		letter-spacing: 0 string.unquote($__important);
		line-height: 0 string.unquote($__important);
		text-shadow: none string.unquote($__important);
	}

	@if $type == visually {
		border: 0 string.unquote($__important);
		clip: rect(1px, 1px, 1px, 1px) string.unquote($__important);
		left: 0;
		max-height: 1px string.unquote($__important);
		max-width: 1px string.unquote($__important);
		overflow: hidden string.unquote($__important);
		padding: 0 string.unquote($__important);
		position: absolute string.unquote($__important);
		top: 0;
	}
}

.visible {
	visibility: visible;
}

.invisible {
	visibility: hidden;
}
