/*md

# Refinement checkbox

TBD

*/

.b-refinement_checkbox {
	@include g-checkbox;
	color: #000;
	padding: 4px 2px 0;

	cursor: pointer;

	&:hover {
		text-decoration: none;
	}

	&[aria-disabled='true'] {
		opacity: 0.6;
		pointer-events: none;
	}

	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox:hover & {
			box-shadow: 0 0 0 1px $color-action;
		}

		.b-refinement_checkbox[aria-checked='true'] & {
			@include g-checkbox(_icon, m-checked);

			border: 1px solid #000;
			path {
				transform: scale(0.7);
			}
		}

		.b-refinement_checkbox[aria-disabled='true'] & {
			@include g-checkbox(_icon, m-disabled);
		}
	}

	&-label {
		font-weight: 500;
		font-size: 14px;
	}
}
