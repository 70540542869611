.b-load_progress {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 30px;

	@include media(sm) {
		margin-top: 10px;
	}

	&-description {
		font-size: 16px;
		margin-bottom: 15px;
	}

	&-indicator {
		background-color: $color-divider;
		height: 4px;
		max-width: 408px;
		width: 100%;
	}

	&-value {
		background-color: $color-beige;
		height: 100%;
	}
}
