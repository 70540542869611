.b-header_category {
	margin: 20px 0;

	&-title {
		@include g-section_holder;
		@include g-heading_1;

		@include media(sm) {
			text-align: center;
		}
	}
}
