.b-refinement {
	$root: &;

	&-list {
		margin-top: 8px;
		overflow: hidden; // prevent from margins push bounds over so scroll is showing up

		@include media(sm) {
			margin-top: 0;
		}

		@include media(lg-up) {
			max-height: 300px;
			overflow-y: auto;
		}

		&.m-checkboxes {
			margin: rh(0 -4);
		}

		&.m-numeric {
			grid-template-columns: repeat(4, auto);

			.b-refinement_swatch {
				width: 48px;
			}
		}
	}

	&-list_item {
		margin: 10px 0;

		@include media(sm) {
			margin-top: 5px;
		}

		.b-refinement-list.m-swatches & {
			margin: 0 0 16px;

			&:last-child {
				margin-bottom: 9px;
			}
		}

		#{$root}-list.m-checkboxes & {
			margin: 0;
			padding: rh(3 4);
		}

		#{$root}-list.m-swatches & {
			margin: 0 0 16px;

			&:last-child {
				margin-bottom: 9px;
			}
		}
	}

	&-sublist {
		margin-top: 13px;

		@include media(sm) {
			margin-top: 15px;
		}

		.b-refinement-list & {
			padding-left: 17px;
		}

		#{$root}-list_item {
			margin: 0 0 8px;

			&:first-child {
				margin-bottom: 11px;
			}
		}

		.b-refinement_link {
			display: inline-block;
			margin-top: 5px;

			&.m-selected {
				margin-top: 0;
			}
		}

		.m-hidden {
			display: none;
		}
	}
}
