/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/

@mixin g-icon_gradient {
	align-items: center;
	background-image: linear-gradient(to bottom, rgba($color-primary, 0.14), rgba($color-primary, 0.34));
	border-radius: 100%;
	color: $color-primary;
	display: flex;
	flex-shrink: 0;
	height: 65px;
	justify-content: center;
	overflow: hidden;
	width: 65px;
}
