/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/

@mixin g-dialog_backdrop {
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	transition: $motion-ease-popups;
	transition-property: visibility, background-color;
	visibility: hidden;
	z-index: z(modal);

	&.m-opened,
	&.m-active {
		background-color: rgba($color-bg-overlay, 0.4);
		overflow-y: scroll;
		visibility: visible;
	}
}
