.b-applied_filters {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 15px;

	@include media(lg-down) {
		display: none;
	}

	&-title {
		font-weight: 500;
	}

	&-items {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		width: 100%;
	}

	&-item {
		align-items: center;
		border: $global-line solid adjust-color-to-bg($color-bg, rgba($color-white, 0.3), rgba($color-black, 0.3));
		display: flex;
		height: 29px;
		margin-bottom: 15px;
		margin-right: 10px;
		overflow: hidden;
		padding: 0;
		padding-left: 8px;
		user-select: none;

		&:hover {
			border-color: $color-action;
		}
	}

	&-clear_all {
		@include g-link;

		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
		position: relative;
		text-decoration: underline;

		&:hover {
			color: $color-action;
			text-decoration: none;
		}
	}

	&-name {
		font-weight: 300;
		margin-inline-end: rh(2);
	}

	&-value {
		font-size: 12px;
		font-weight: 400;
		margin-inline-end: rh(2);
		text-transform: capitalize;
	}

	&-color_icon {
		border: 1px solid adjust-color-to-bg($color-bg, rgba($color-white, 0.3), rgba($color-black, 0.3));
		border-radius: 50%;
		height: 18px;
		margin-inline-end: rh(1);
		width: 18px;
	}

	&-remove_button {
		@include g-button_iconed;

		height: 29px;
		transition: $motion-fast;
		transition-property: background, color;
		width: 28px;

		&:hover {
			background: $color-action;
			color: $color-white;
		}

		svg {
			height: 10px;
			width: 10px;
		}
	}
}
