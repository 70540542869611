/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/

@mixin g-heading_1 {
	font-size: 39px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

@mixin g-heading_2 {
	font-size: 27px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

@mixin g-heading_3 {
	font-size: 23px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

@mixin g-heading_4 {
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

@mixin g-heading_5 {
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

@mixin g-heading_6 {
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

@mixin g-text($size: 16, $weight: 400) {
	font-size: $size;
	font-weight: $weight;
}
