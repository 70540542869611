.l-plp {
	@include media(xl) {
		display: flex;
	}

	&-refinement {
		@include media(xl) {
			position: relative;
			width: grid-span($column: 3, $with-gutter: false);
		}

		@include media(lg-down) {
			display: none;
		}

		&.m-no_results {
			display: none;
		}
	}

	&-refinements_slide {
		@include media(xl) {
			display: none;
		}

		&.m-no_results {
			display: none;
		}
	}

	&-products {
		width: 100%;

		@include media(xl) {
			padding-left: 5px;
			width: grid-span($column: 9, $with-gutter: false);

			&.m-no_results {
				width: grid-span($column: 12, $with-gutter: true);
			}
		}

		&.m-no_results {
			margin-top: 12px;
			padding: 0;
		}
	}
}
